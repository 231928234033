import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import WalletConnectProvider from "@walletconnect/web3-provider";
import Logo from "../../images/logo.png";
import Menu from "../../images/menu.png";
import Wallet from "../../images/wallet.png";
import Presale from "../../images/presale.png";
import Swap from "../../images/swap.png";
import Virtual from "../../images/virtual.png";
import Collectibalities from "../../images/collectibalities.png";
import WalletLight from "../../images/walletLight.png";
import PresaleLight from "../../images/presaleLight.png";
import SwapLight from "../../images/swapLight.png";
import VirtualLight from "../../images/virtualizationLight.png";
import Logout from "../../images/logout.png";
import CollectibalitiesLight from "../../images/collactibleLight.png";
import colors from "../../config/colors";
import WalletCompenet from "../../components/../screens/Wallet";
import userWalletActions from "../../redux/users/action";
import UserAmountActions from "../../redux/userAmount/action";
import {
  checkChain_,
  getAccount,
  web3Initializer,
  windowEth,
} from "../../config/metamaskWalletConnector";
import TenupABI from "../../ethereum/tenupsmart.json";
import web3Object from "../../web3/web3";
import BasicModal from "../Modal";
import WalletConnect from "../../images/walletConnect.png";
import bitkeep from "../../images/BitKeep logo for wallet connect.svg";
import Meta from "../../images/meta.png";
import campton from "../../fonts/campton/CamptonBlack.otf";
import catamaran from "../../fonts/catamaran/Catamaran.ttf";
import Web3 from "web3";
import { useAlert } from "react-alert";
// import WalletConnectProvider from "@walletconnect/web3-provider";
import { EthereumProvider } from '@walletconnect/ethereum-provider'

import "./index.css";
import axiosObject from "../../config/axios";
import api from "../../api";
import axios from "axios";
import Loader from "../Loader";
import {
  checkBitKeepInstalled,
  checkChain_bitkeep,
  initializeBitKeep,
} from "../../config/bitkeep";

const drawerWidth = 240;

function DrawerComponent(props) {
  const { window, children } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selected, setSelected] = React.useState("Wallet");
  const location = useLocation();
  const [loader, setLoader] = React.useState(false);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { setUser } = userWalletActions;
  const { setUserAmount } = UserAmountActions;
  const [isConnected, setConnected] = React.useState(0);
  const user = useSelector((state) => state.userReducer);
  const userAmount = useSelector((state) => state.userAmountReducer);
  const [userbalance, setBalance] = React.useState("0");
  const getContract = (web3, abi, address) =>
    new web3.eth.Contract(abi, address);
  const [open, setOpen] = React.useState(false);
  const alert = useAlert();
  let walletCon = localStorage.getItem("con");

  React.useEffect(() => {
    if (user.users) {
      checkChain();
      checkIfTokenExist();
    }
  }, []);

  const checkChain = async (param) => {
    try {
      let id = param;
      if (!param) {
        let walletNo = localStorage.getItem("con");

        if (!walletNo) {
          await Logoutusr();
        }

        id = walletNo;
      }

      const chain = await checkChain_();
      if (parseInt(id) === 3) {
        const checkChain_bitkeep_ = await checkChain_bitkeep();
        // console.log(checkChain_bitkeep_);
        if (checkChain_bitkeep_ !== false) {
          if (
            parseInt(checkChain_bitkeep_) !==
            parseInt(process.env.REACT_APP_CHAIN_ID)
          ) {
            // console.warn(checkChain_bitkeep_);
            // console.warn(process.env.REACT_APP_CHAIN_ID);
            alert.show("Connect to mainnet");
            await Logoutusr();
            return false;
          } else {
            return true;
          }
        } else {
          alert.show("Connect to mainnet");
          throw new Error("Chain not matched");
        }
      } else {
        if (chain !== false) {
          // console.log(parseInt(chain));
          if (parseInt(chain) !== parseInt(process.env.REACT_APP_CHAIN_ID)) {
            alert.show("Connect to mainnet");
            await Logoutusr();
            return false;
          } else {
            return true;
          }
        } else {
          if (id === 2) {
            alert.show("Please install metamask and refresh your browser.");
            return false;
          } else if (id === 1) {
            return true;
          }
        }
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const connectBitKeep = async () => {
    try {
      const chain = await checkChain(3);

      if (!chain) {
        return 0;
      }

      setLoader(true);
      const bitKeepInstalled = await checkBitKeepInstalled();
      const bitKeepProvider = await initializeBitKeep();
      const isConnected = await bitKeepProvider.isConnected();
      const account = await bitKeepProvider.request({
        method: "eth_requestAccounts",
      });

      if (!isConnected) {
        throw new Error("bit keep provider error");
      }

      const user = await api.login({ address: account[0] });

      if (!user) {
        alert.show("Metamask connection failed");
        throw new Error("Metamask connection failed");
      }

      axios.defaults.headers.common["Authorization"] = `Bearer ${user}`;

      const isUser = await api.checkandGenerateNonce();
      let signature;

      web3Object.web3 = new Web3(bitKeepInstalled);

      if (isUser.nonce) {
        signature = await web3Object.web3.eth.personal.sign(
          `I am signing my one-time nonce: ${isUser.nonce}`,
          account[0],
          ""
        );
      }

      const payld = {
        signature: signature,
        address: account[0],
      };
      const signVerify = await api.verifySIgn(payld);

      if (signVerify) {
        localStorage.setItem("token", user);
        localStorage.setItem("sign", signature);

        dispatch(setUser(account[0]));
        localStorage.setItem("con", 3);
        setOpen(false);
      } else {
        alert.show("Authentication failed.");
      }

      setLoader(false);
    } catch (e) {
      alert.show("Bitkeep not connected, Error!");
      setLoader(false);
    }
  };

  const walletConnectValidator = async (chain) => {
    try {
      // console.log(parseInt(chain) !== parseInt(process.env.REACT_APP_CHAIN_ID));
      // console.warn(parseInt(chain), parseInt(process.env.REACT_APP_CHAIN_ID));

      if (parseInt(chain) !== parseInt(process.env.REACT_APP_CHAIN_ID)) {
        alert.show("Please connect to mainnet");
        return false;
      } else {
        return true;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const checkIfTokenExist = async () => {
    if (user.users) {
      const token = localStorage.getItem("token");
      const sign = localStorage.getItem("sign");
      if (token && sign) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        await validateTokenAndSign();
      } else {
        await Logoutusr();
      }
    }
  };

  const validateTokenAndSign = async () => {
    try {
      const res = await api.checkIfToken({
        address: user?.users?.toString()?.toLowerCase(),
      });

      if (!res) {
        alert.show("Session Expired or invalid, Please connect again");
        await Logoutusr();
        return;
      } else {
        const sign = localStorage.getItem("sign");
        const payld = {
          signature: sign,
          address: user.users,
        };
        const signVerify = await api.verifySIgn(payld);

        if (!signVerify) {
          alert.show("Session Expired or invalid, Please connect again");
          await Logoutusr();
        }
      }
    } catch (e) {
      alert.show("Session Expired or invalid, Please connect again");
      await Logoutusr();
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  React.useEffect(() => {
    getBalance();
  }, [userAmount.users]);

  const getBalance = async () => {
    try {
      const contract = getContract(
        web3Object.web3,
        TenupABI,
        process.env.REACT_APP_TENUP_SMART_ADDRESS
      );
      const balance = await contract.methods.balanceOf(user.users).call();
      const wei = parseFloat(
        web3Object?.web3?.utils?.fromWei(balance)
      )?.toFixed(3);

      setBalance(wei);
    } catch (e) {
      console.log(e);
    }
  };

  // const providerBsc = new WalletConnectProvider({
  //   rpc: {
  //     56: "https://bsc-dataseed.binance.org/",
  //     97: "https://data-seed-prebsc-1-s1.binance.org:8545",
  //     1: "https://mainnet.infura.io/v3/3f2f5e20071a4cbe9dd30a0090ff6402",
  //     3: "https://data-seed-prebsc-1-s1.binance.org:8545",
  //     qrcode: true,
  //     pollingInterval: 12000,
  //   },
  //   chainId: parseInt(process.env.REACT_APP_CHAIN_ID),
  // });

  const connectWalletConnect = async () => {
    try {
      // provider
      setLoader(true);

      const providerBsc = await EthereumProvider.init({
        projectId: process.env.REACT_APP_WC_PROJECT_ID, // REQUIRED your projectId
        chains: [parseInt(process.env.REACT_APP_CHAIN_ID)], // REQUIRED chain ids
        showQrModal: true, // REQUIRED set to "true" to use @walletconnect/modal,
      });

      // const chain = await checkChain(1);
      // console.log("wallet");

      // if (!chain) {
      //   return 0;
      // }

      localStorage.removeItem("walletconnect");
      dispatch(setUser(""));
      setLoader(false);
      handleClose()
      const providerWallerCon = await providerBsc.enable();
      // console.log(providerWallerCon);
      setLoader(true);
      // const result = await providerBsc.request("eth_chainId");
      const web33 = new Web3(providerBsc);
      web3Object.web3 = web33;

      const result = await web33.eth.getChainId();
      // console.log(result);
      const valid = await walletConnectValidator(result);
      if (!valid) {
        throw new Error("Failed");
      }


      var accs = await web33.eth.getAccounts();
      // console.log(accs);
      if (accs.length > 0) {
        const userWalletId = accs[0];
        const user = await api.login({ address: userWalletId });

        if (!user) {
          alert.show("Metamask connection failed");
          throw new Error("Metamask connection failed");
        }

        axios.defaults.headers.common["Authorization"] = `Bearer ${user}`;

        const isUser = await api.checkandGenerateNonce();
        let signature;

        if (isUser.nonce) {
          signature = await web3Object.web3.eth.personal.sign(
            `I am signing my one-time nonce: ${isUser.nonce}`,
            userWalletId,
            ""
          );
        }

        const payld = {
          signature: signature,
          address: userWalletId,
        };
        const signVerify = await api.verifySIgn(payld);

        if (signVerify) {
          localStorage.setItem("token", user);
          localStorage.setItem("sign", signature);

          dispatch(setUser(accs[0]));
          localStorage.setItem("con", 1);
          setOpen(false);
        } else {
          alert.show("Authentication failed.");
          setLoader(false);
          setOpen(false);
        }
      } else {
        alert.show("Connect to metamask");
        setLoader(false);
        setOpen(false);
      }

      setLoader(false);
      setOpen(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
      setOpen(false);
    }
  };

  const connect = async () => {
    try {
      const chain = await checkChain(2);
      // console.log(chain);
      if (!chain) {
        return 0;
      }
      setLoader(true);
      await web3Initializer(alert);

      const userWalletId = await getAccount();

      if (!userWalletId) {
        throw new Error(
          "Unable to connect user, Please try again! See logs for more detailed error."
        );
      }

      const user = await api.login({ address: userWalletId });

      if (!user) {
        alert.show("Metamask connection failed");
        throw new Error("Metamask connection failed");
      }

      axios.defaults.headers.common["Authorization"] = `Bearer ${user}`;

      const isUser = await api.checkandGenerateNonce();
      let signature;

      if (isUser.nonce) {
        signature = await web3Object.web3.eth.personal.sign(
          `I am signing my one-time nonce: ${isUser.nonce}`,
          userWalletId,
          ""
        );
      }

      const payld = {
        signature: signature,
        address: userWalletId,
      };
      const signVerify = await api.verifySIgn(payld);

      if (signVerify) {
        localStorage.setItem("token", user);
        localStorage.setItem("sign", signature);

        dispatch(setUser(userWalletId));
        localStorage.setItem("con", 2);
        setOpen(false);
      } else {
        alert.show("Authentication failed.");
      }

      setLoader(false);
    } catch (e) {
      setOpen(false);
      setLoader(false);
    }
  };

  const onLoginClickHandler = async () => {
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
  };

  const Logoutusr = () => {
    localStorage.removeItem("persist:root");
    localStorage.removeItem("token");
    localStorage.removeItem("sign");
    localStorage.removeItem("walletconnect");
    localStorage.removeItem("con ");

    dispatch(setUser(""));
    dispatch(setUserAmount("0.00"));
  };

  React.useEffect(() => {
    if (location.pathname === "/") {
      setSelected("Wallet");
    }
    if (location.pathname === "/wallet") {
      setSelected("Wallet");
    } else if (location.pathname === "/swap") {
      setSelected("Swap");
    } else if (location.pathname === "/signal") {
      setSelected("Signals");
    } else if (location.pathname === "/virtual-mining") {
      setSelected("Virtual Mining");
    } else if (location.pathname === "/swap") {
      setSelected("Swap");
    } else if (location.pathname === "/presale") {
      setSelected("Presale");
    } else if (location.pathname === "/approval") {
      setSelected("Approvals");
    }else if (location.pathname === "/lock-tup") {
      setSelected("Access with TenUp");
    }
  }, [location.pathname]);

  const redirect = (param) => {
    if (param === "Wallet") {
      setSelected("Wallet");
      navigation("/wallet");
    }
    if (param === "Signals") {
      setSelected("Signals");
      navigation("/signal");
    }
    if (param === "Virtual Mining") {
      setSelected("Virtual Mining");
      navigation("/virtual-mining");
    }
    if (param === "Access with TenUp") {
      setSelected("Access with TenUp");
      navigation("/lock-tup");
    }
    if (param === "Swap") {
      setSelected("Swap");
      navigation("/swap");
    }
    if (param === "Presale") {
      setSelected("Presale");
      navigation("/presale");
    }
    if (param === "Approvals") {
      setSelected("Approvals");
      navigation("/approval");
    }
  };

  const drawer = (
    <div>
      <div>
        <ListItemIcon
          style={{
            padding: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={Logo} style={{ width: "75%", height: "auto" }} alt="Icon" />
        </ListItemIcon>
        <List
          style={{
            height: "60vh",
            padding: 20,
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ListItem
            key={"Wallet"}
            onClick={() => redirect("Wallet")}
            disablePadding
          >
            <ListItemButton>
              <ListItemIcon>
                <img
                  src={selected === "Wallet" ? Wallet : WalletLight}
                  alt="Icon"
                />
              </ListItemIcon>
              <ListItemText
                style={{ color: selected === "Wallet" ? "black" : "#ababab" }}
                primary={"Wallet"}
              />
            </ListItemButton>
          </ListItem>

          {/* <ListItem
            key={"Presale"}
            onClick={() => redirect("Presale")}
            disablePadding
          >
            <ListItemButton>
              <ListItemIcon>
                <img
                  src={selected === "Presale" ? Presale : PresaleLight}
                  alt="Icon"
                />
              </ListItemIcon>
              <ListItemText
                primary={"Presale"}
                style={{ color: selected === "Presale" ? "black" : "#ababab" }}
              />
            </ListItemButton>
          </ListItem> 

          <ListItem
            key={"Signals"}
            onClick={() => redirect("Signals")}
            disablePadding
          >
            <ListItemButton>
              <ListItemIcon>
                <img
                  src={selected === "Signals" ? Presale : PresaleLight}
                  alt="Icon"
                />
              </ListItemIcon>
              <ListItemText
                primary={"Signals"}
                style={{ color: selected === "Signals" ? "black" : "#ababab" }}
              />
            </ListItemButton>
          </ListItem> */}



          <ListItem
            key={"Virtual Mining"}
            onClick={() => redirect("Virtual Mining")}
            disablePadding
          >
            <ListItemButton>
              <ListItemIcon>
                <img
                  src={selected === "Virtual Mining" ? Virtual : VirtualLight}
                  alt="Icon"
                />
              </ListItemIcon>
              <div>
                <ListItemText
                  style={{
                    color: selected === "Virtual Mining" ? "black" : "#ababab",
                  }}
                  primary={"Virtual Mining"}
                />
              </div>
            </ListItemButton>
          </ListItem>
        {/* <ListItem
            key={"Access with TenUp"}
            onClick={() => redirect("Access with TenUp")}
            disablePadding
          >
            <ListItemButton>
              <ListItemIcon>
                <img
                  src={selected === "Access with TenUp" ? Virtual : VirtualLight}
                  alt="Icon"
                />
              </ListItemIcon>
              <div>
                <ListItemText
                  style={{
                    color: selected === "Access with TenUp" ? "black" : "#ababab",
                  }}
                  primary={"Access with TenUp"}
                />
              </div>
            </ListItemButton>
          </ListItem> */}
          {/* <ListItem
            onClick={() => redirect("Swap")}
            key={"Swap"}
            disablePadding
          >
            <ListItemButton style={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <img src={selected === "Swap" ? Swap : SwapLight} alt="Icon" />
              </ListItemIcon>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      color: selected === "Swap" ? "black" : "#ababab",
                      fontSize: 16,
                    }}
                  >
                    Swap
                  </span>
                </div>
              </div>
            </ListItemButton>
          </ListItem> */}
          {user?.users?.toString().toLowerCase() ===
            process.env.REACT_APP_ADMIN_WALLET.toString().toLowerCase() && (
            <ListItem
              key={"Approvals"}
              onClick={() => redirect("Approvals")}
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon>
                  <img
                    src={selected === "Approvals" ? Virtual : VirtualLight}
                    alt="Icon"
                  />
                </ListItemIcon>
                <div>
                  <ListItemText
                    style={{
                      color: selected === "Approvals" ? "black" : "#ababab",
                    }}
                    primary={"Approvals"}
                  />
                </div>
              </ListItemButton>
            </ListItem>
          )}
          {/* <ListItem
            key={"Collectibles"}
            disabled={true}
            // onClick={() => setSelected("Collectibles")}
            disablePadding
          >
            <ListItemButton style={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <img
                  src={
                    selected === "Collectibles"
                      ? Collectibalities
                      : CollectibalitiesLight
                  }
                  alt="Icon"
                />
              </ListItemIcon>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <span style={{ color: "#ababab", fontSize: 16 }}>
                  Collectiables
                </span>
                <span
                  style={{
                    color: "#ababab",
                    fontSize: 13,
                    marginTop: -5,
                    fontWeight: 100,
                  }}
                >
                  coming soon
                </span>
              </div>
            </ListItemButton>
          </ListItem> */}
          <ListItem></ListItem>
        </List>
      </div>
      {user.users.length > 0 && (
        <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
          <List style={{ padding: 20 }}>
            <Button
              variant="contained"
              onClick={(e) => Logoutusr()}
              sx={{
                backgroundColor: colors.CONTAINER_DARK,
                width: "100%",
                color: "white",
              }}
              startIcon={<img src={Logout} alt="Icon" />}
            >
              Logout
            </Button>
          </List>
        </div>
      )}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        style={{ padding: 10, backgroundColor: "white" }}
        position="fixed"
        elevation={0}
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar style={{ display: "flex" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            {/* <MenuIcon /> */}
            <img src={Menu} alt="Icon" />
          </IconButton>
          <Typography
            sx={{ fontSize: { xs: "1.3em", md: " 1.5rem", lg: "1.8rem" } }}
            style={{
              fontWeight: "bold",
              color: colors.HEADING_TEXT,
              fontFamily: "arial",
            }}
            variant="h6"
            noWrap
            component="div"
          >
            {selected}
          </Typography>
          <div
            style={{
              marginLeft: "auto",
            }}
          >
            {user?.users.length > 0 && (
              <Typography
                sx={{
                  color: "red",
                  backgroundColor: colors.BTN_CONTAINER,
                  color: "white",
                  p: { xs: 0.5, sm: 1 },
                  borderRadius: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {user.users.slice(0, 4) +
                  " ... " +
                  user.users.slice(user.users.length - 4)}{" "}
                <span
                  style={{
                    backgroundColor: colors.CONTAINER_DARK,
                    marginLeft: 10,
                    padding: 5,
                    borderRadius: 10,
                  }}
                >
                  {userAmount.userAmount > 0 ? userAmount.userAmount : "0.00"}{" "}
                  TUP
                </span>
              </Typography>
            )}

            {user?.users.length <= 0 && (
              <Button
                sx={{
                  backgroundColor: colors.BTN_CONTAINER,
                  borderRadius: 4,
                  color: "white",
                  pl: 4,
                  pr: 4,
                  pt: 1.5,
                  pb: 1.5,
                  boxShadow: 4,
                }}
                variant="contained"
                onClick={onLoginClickHandler}
                disabled={isConnected > 0 ? true : false}
              >
                Connect
              </Button>
            )}
          </div>
        </Toolbar>

        <Toolbar>
          <Typography
            sx={{ fontSize: { xs: "0.8em", sm: " 1.2rem" } }}
            style={{
              fontWeight: "normal",
              color: colors.HEADING_TEXT_LIGHT,
              fontFamily: "arial",
            }}
            variant="h6"
            noWrap
            component="div"
          >
            Tenup Nation: For Progress, Investment & Success
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, pl: 7, pt: 25, pr: 7 }}>
        {children}
      </Box>
      <BasicModal
        heading={"Recieve Money"}
        open={open}
        isCopyToken={false}
        onCancel={handleClose}
        userAcc={user.users}
        isLowOnWidth={true}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
        <img
            // onClick={()=>alert("a")}
            src={bitkeep}
            onClick={() => connectBitKeep()}
            className="iconsModal1"
          />
            <img src={Meta} onClick={() => connect()} className="iconsModal2" />
          <img
            // onClick={()=>alert("a")}
            src={WalletConnect}
            onClick={() => connectWalletConnect()}
            className="iconsModal3"
          />
          
        </div>
      </BasicModal>
      <Loader open={loader} />
    </Box>
  );
}
DrawerComponent.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerComponent;
