import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, Alert, CircularProgress, Input, InputAdornment, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Modal from "@mui/material/Modal";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from "../../components/Drawer";
import colors from "../../config/colors";
import Logo from "../../images/logoWOT.png";
import background from "../../images/bg.png";

import useStyles from "../Virtual/style";
import web3Object from "../../web3/web3";
import StakingABi from "../../ethereum/stakeTenupv2.json";
import TOKENABI from "../../ethereum/erc20_abi.json";
import TenupABI from "../../ethereum/tenupsmart.json";
import LockedABI from "../../ethereum/lockABI";
import { useDispatch, useSelector } from "react-redux";
import BigNumber from "bignumber.js";
import { useAlert } from "react-alert";
import bg_claim from "../../images/Group 1947-1.png";
import Loader from "../../components/Loader";
import UserAmountAction from "../../redux/userAmount/action";
import Web3 from "web3";
import iconDown from "../../images/down-filled-triangular-arrow.png";
import calc from "../../images/keys.png";
import save from "../../images/save-money.png";
import { DataGrid } from "@mui/x-data-grid";
import api from "../../api";

const toHex = (amount) => "0x" + amount.toString(16);
const LockTUP = (props) => {
    const classes = useStyles(props);
  
    return (
      <>
        <Drawer>
          <LockTUPComp />
        </Drawer>
      </>
    );
  };

const LockTUPComp = (props) => {
  const getContract = (web3, abi, address) =>
    new web3.eth.Contract(abi, address);
  const user = useSelector((state) => state.userReducer);
  // const userBalance = useSelector((state) => state.userAmountReducer);
  const classes = useStyles(props);
  const alert = useAlert();
  const [loader, setLoader] = useState(false);
  const [alreadyLocked, setAlreadyLocked] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [userBalance, setUserBalance] = useState("");
  const [lockedObject, setLockedObject] = useState({});
  const [allLokers, setAllLokers] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { setUserAmount } = UserAmountAction;
  const dispatch = useDispatch();
  const web33 = new Web3(process.env.REACT_APP_GET_RPC);
  // console.log(process.env.REACT_APP_STAKING_ADDRESS);
  let STAKE = getContract(
    web33,
    StakingABi,
    process.env.REACT_APP_STAKING_ADDRESS
  );
  let TENUP = getContract(
    web3Object.web3,
    TenupABI,
    process.env.REACT_APP_STAKING_ADDRESS
  );


  useEffect(() => {
    if(user.users) {
      getBalance();
      checkIsLocked()
      getBalance2()
    }else{
      setAlreadyLocked(false)
    }
  }, [user.users]);

  useEffect(() => {
    if((user?.users && ((process.env.REACT_APP_ADMIN_WALLET).toLowerCase() === (user?.users)?.toLowerCase()))) {
      getAllLockers()
    }
  }, [user.users]);
  // console.log(userBalance);
  


  const isApproved = async (tok, owner, approvedAddress, totalAmount) => {
    try {
      let r = await tok.methods.allowance(owner, approvedAddress).call();
      // console.log(r);
      if (parseInt(r) < totalAmount) {
        let val = new BigNumber(web3Object.web3.utils.toWei("100000"));
        let finPrice = toHex(val);
        let a = await tok.methods.approve(approvedAddress, finPrice).send({
          from: owner,
        })
        // console.log(a);
        return true;
      } else {
        return true;
      }
    } catch (e) {
      console.log("Approve Issue", e);
      return false;
    }
  };

  const getBalance = async () => {
    try {
      let TOKEN = getContract(
        web3Object.web3,
        TOKENABI,
        process.env.REACT_APP_LOCK_ERC_CONTRACT_ADDRESS
      );
      // console.log(TOKEN);
      
      const balance = await TOKEN.methods.balanceOf(user.users).call();
      
      const wei = parseFloat(
        web3Object.web3?.utils?.fromWei(balance)
      )?.toFixed(3);
      // console.log("balance");
      // console.log(balance);
      // console.log(wei);
      setUserBalance(wei)
    } catch (e) {
      console.log(e);
    }
  };

  const checkIsLocked = async()=> {
      try {
        let lockContract = getContract(
        web3Object.web3,
        LockedABI,
        process.env.REACT_APP_LOCK_CONTRACT_ADDRESS
        );
        const lockeRes = await lockContract.methods.lockers(user.users).call();
        // console.log("lockeRes");
        // console.log(lockeRes);
        if(lockeRes.lockedAmount != "0"){
          setLockedObject(lockeRes)
          setAlreadyLocked(true)
        }
      } catch (e) {
        console.log(e);
      }
  }

  const submitLock = async()=> {
    try {    
      let regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]+)*$/;
      if(!user.users){
        return alert.show("Please connect wallet")
      }
      if(!emailAddress){
        return alert.show("Please enter your email address")
      }
      if (!regexEmail.test(emailAddress)) {
        return alert.show("Email is not correctly formatted")
      }
      if (emailAddress.length > 40) {
        return alert.show("Email length is not greater than")
      }

      setLoader(true)
      let val = new BigNumber(web3Object.web3.utils.toWei('100000'));
      val = toHex(val);
      // approveAmount
      let TOKEN = getContract(
        web3Object.web3,
        TOKENABI,
        process.env.REACT_APP_LOCK_ERC_CONTRACT_ADDRESS
      );
      // console.log(userBalance);
      
      if(userBalance >= 100000){
        let checkApprove = await isApproved(
          TOKEN,
          user.users,
          process.env.REACT_APP_LOCK_CONTRACT_ADDRESS,
          val
        )
        // approveAmount
        // console.log(checkApprove);
        if(!checkApprove) {
          setLoader(false)
          return alert.show("Transaction not approve lock coin")
        }
        let lockContract = getContract(
          web3Object.web3,
          LockedABI,
          process.env.REACT_APP_LOCK_CONTRACT_ADDRESS
        );
        let res = await lockContract.methods.LockTUP(emailAddress).send({ from: user?.users })
          // .on('transactionHash', function(hash){
          //   console.log(hash);
          // })
          // .on('confirmation', function(confirmationNumber, receipt){
          //   console.log(confirmationNumber );
          //   console.log(receipt );
          // });
          
          console.log(res);
          // if(res?.success){
            
          addLocker()
          setAlreadyLocked(true)
          setLoader(false)
          getBalance2()

          // }
        
      }else{
        alert.show("Insuficient balance to lock coins")
        setLoader(false)
      }
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  } 

  const addLocker = async() => {
    try {
      
      let payload = {
        email:emailAddress,
        lockedAmount:100000,
      }
      let resp = await api.addLocker(payload)
      // console.log(resp);
      handleOpen()
    } catch (error) {
      console.log(error);
      
    }
  }

  const getAllLockers = async()=> {
    try {
      let lockContract = getContract(
      web3Object.web3,
      LockedABI,
      process.env.REACT_APP_LOCK_CONTRACT_ADDRESS
      );
      const lockeRes = await lockContract.methods.lockersList().call();
      console.log("lockeRes");
      let temp = [];
      // console.log(lockeRes);
      for (let i = 0; i < lockeRes.length; i++) {
        temp.push({ id: i+1, lockedAmount: 100000, tgId: lockeRes[i]?.tgId });
      }
  
      setAllLokers(temp)
     
    } catch (e) {
      console.log(e);
    }
}


  const columns = [
    { field: "id", headerName: "S.No.", flex: 1 },
    { field: "lockedAmount", headerName: "Locked Amount", flex: 1 },
    { field: "tgId", headerName: "Email Id", flex: 1 },
  ];

  const getBalance2 = async () => {
    try {
      setLoader(true);
      const contract = getContract(
        web3Object.web3,
        TenupABI,
        process.env.REACT_APP_TENUP_SMART_ADDRESS
      );
      const balance = await contract.methods.balanceOf(user.users).call();
      const wei = parseFloat(
        web3Object?.web3?.utils?.fromWei(balance)
      )?.toFixed(3);
      
      dispatch(setUserAmount(wei));

      // setBalance(wei);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };
  

  return (
    <>
    {(user?.users && ((process.env.REACT_APP_ADMIN_WALLET).toLowerCase() == (user?.users)?.toLowerCase())) ?
    <>
      <div style={{ height: "370px", width: "100%" }}>
        <DataGrid
          rows={allLokers}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          // checkboxSelection
          // onSelectionModelChange={(e) => setSelected(e)}
        />
      </div>
    </>
    :
    <>
      <Card
        elevation={5}
        sx={{
          minWidth: 20,
          marginTop: 0,
          borderRadius: 5,
          backgroundImage: "linear-gradient(#05b4ef, #1072ac)",
        }}
      >
        <CardContent>
          <Typography
            sx={{
              fontSize: 18,
              color: colors.BLACK,
              p: 2,
              pb:0,
              fontWeight: "bold",
              color: colors.WHITE,
            }}
          >
            {alreadyLocked ?
            <>
            Success!
            </>
            :
            "Exclusive Token Lock Event"
            }

          </Typography>
        </CardContent>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "row",
            justofyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {/* <div style={{overflow: 'hidden', height: "100%" }}>
            <img style={{position: 'absolute', right:-70, top:0}} src={bg_claim} />
            </div> */}
          <Typography
            sx={{
              fontSize: 15,
              color: colors.CONTAINER_LIGHT,
              pl: 2,
              fontWeight: "normal",
              color: colors.WHITE,
            }}
          >
            <p style={{margin:0}}>Lock 100,000 TUP tokens on the TenUp Dapp for 1 month and receive a 100 USDT reward at the end of the locking period!</p>
            <p style={{marginTop:0}}>Your tokens will be safely stored, and after 30 days, you'll be able to unlock your tokens along with the reward.</p>
            <p>Why Participate?</p>
            <p>💰 Earn 100 USDT for locking your tokens.</p>
            <p>🔒 Secure and Transparent: Tokens are locked on a decentralized platform.</p>
            <p>🌐 Support the Ecosystem: Strengthen the TenUp network while earning rewards.</p>
          </Typography>
        </CardContent>
      </Card>
      <Card
        elevation={5}
        sx={{
          minWidth: 20,
          marginTop: 10,
          borderRadius: 5,
          marginBottom: 5,
          marginTop: 5,
        }}
      >
        <CardContent>
          <Typography
            sx={{
              fontSize: 18,
              color: colors.BLACK,
              p: 2,
              fontWeight: "bold",
            }}
          >
            {alreadyLocked ?
            "You have successfully locked 72,000 TenUp tokens and will now start receiving daily crypto signals directly to your email address."          
            :
            <>
            {/* Lock 72,000 TenUp Tokens and Start Receiving Trading Signals Straight to Your Email! */}
            Ready to Join?
            <br />
              {/* <div style={{fontSize:12}}>
                Note: Once you lock these tokens, they will not be unlocked. The tokens will be permanently transferred to us.
              </div> */}
            </>
            }
          </Typography>
        </CardContent>
        <div
          className="locktupEmailMain"
        >
          <div >
            
            {/* <CardContent style={{ paddingLeft: '0px', display: "flex", flexDirection: "row" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  color: colors.CONTAINER_LIGHT,
                  fontWeight: "normal",
                }}
              >
                Click submit to lock 1000 TUP.
              </Typography>
            </CardContent> */}
            {/* {new BigNumber(stakedAmount) <= 0 && ( */}
            {alreadyLocked ?""
            :
            <>
              <CardContent style={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  sx={{
                    fontSize: 15,
                    color: colors.CONTAINER_LIGHT,
                    pl: 2,
                    fontWeight: "normal",
                  }}
                >
                  Please provide your Email before locking your TenUp tokens.
                </Typography>
              </CardContent>
            {/* )} */}
            <div
              style={{
                paddingLeft: 30,
                paddingBottom: 30,
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* {new BigNumber(stakedAmount) <= 0 && ( */}
                <input
                  type="email"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                  style={{
                    border: "none",
                    borderBottom: `1px solid ${colors.CONTAINER_LIGHT}`,
                    outline: "none",
                    fontSize: 15,
                  }}
                  disabled={alreadyLocked?true:false}
                ></input>
              {/* )} */}
            
            </div>
            </>
            }
    

          </div>
          {alreadyLocked ?
          <Button
            variant="contained"
            sx={{
              ml: 2,
              mr: 2,
              mb:1,
              background : "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)",
              color: "white",
              boxShadow: 4,
              p: 2,
              borderRadius: 3,
              cursor: "not-allowed"
            }}
          >
            <img style={{ width: 20, marginRight: 10 }} src={calc} />
            Locked
          </Button>
          :
          <Button
            onClick={submitLock}
            variant="contained"
            sx={{
              ml: 2,
              mr: 2,
              mb:1,
              background : "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)",
              color: "white",
              boxShadow: 4,
              p: 2,
              borderRadius: 3,
            }}
          >
            <img style={{ width: 20, marginRight: 10 }} src={calc} />
            Lock Your Tokens Now
          </Button>
          }
        </div>
      </Card>
      <Loader open={loader} />
    </>
    }

<Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <p>Thank you for your support and participation in our token lock event. We will reach out to you via your registered email address after 31 days to coordinate the release of your locked tokens along with the reward. Should you have any questions in the meantime, feel free to contact us. We appreciate your trust in TenUp!</p>
                        <div>Email: <a href="mailto:contact@tenup.io" style={{color:"#1072ac"}}>contact@tenup.io</a></div>
                    </Box>
                </Modal>
    </>
  );
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {sx: "80%", md: "50%"},
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 40,
  p: 4,
  outline: 'none'
};

export default LockTUP;
